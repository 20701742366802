.file-label {
  align-items: stretch;
  display: flex;
  cursor: pointer;
  justify-content: flex-start;
  overflow: hidden;
  position: relative;
}

span {
  font-style: inherit;
  font-weight: inherit;
}

.file-cta {
  background-color: #f5f5f5;
  color: #4a4a4a;
}

.file-cta, .file-name {
  border-color: #dbdbdb;
  border-radius: 4px;
  font-size: 1em;
  padding-left: 1em;
  padding-right: 1em;
  white-space: nowrap;
}

.file-label {
  align-items: stretch;
  display: flex;
  cursor: pointer;
  justify-content: flex-start;
  overflow: hidden;
  position: relative;
}

.file {
  align-items: stretch;
  display: flex;
  justify-content: flex-start;
  position: relative;
  margin-top: -2px;
}

input[type="file" i] {
  -webkit-appearance: initial;
  background-color: initial;
  cursor: default;
  align-items: baseline;
  color: inherit;
  text-align: start !important;
  padding: initial;
  border: initial;
}

.file-input {
  height: 100%;
  left: 0;
  opacity: 0;
  outline: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.button, .file-cta, .file-name, .input, .pagination-ellipsis, .pagination-link, .pagination-next, .pagination-previous, .select select, .textarea {
  -moz-appearance: none;
  -webkit-appearance: none;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: none;
  display: inline-flex;
  font-size: 1rem;
  height: 2.5em;
  justify-content: flex-start;
  line-height: 1.5;
  padding-bottom: calc(.5em - 1px);
  padding-left: calc(.75em - 1px);
  padding-right: calc(.75em - 1px);
  padding-top: calc(.5em - 1px);
  position: relative;
  vertical-align: top;
}

.file-icon {
  align-items: center;
  display: flex;
  height: 1em;
  justify-content: center;
  margin-right: .5em;
  width: 1em;
}