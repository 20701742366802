.react-datepicker-wrapper {
    display: inline-block;
    padding: 0;
    border: 0;
    width: 100%;
}

.date-picker-override {
    border: 1px solid #666;
    border-radius: 6px;
    min-height: 32px;
    height: 32px;
}