#logo {
  background-color: #3c4b64;
  margin-left: -0.9rem;
}

.pointer {
  cursor: pointer;
}

.sidebar-item {
  height: 3rem;
  background-color: #3c4b64;
  transition: background-color 0.5s ease;
}

.sidebar-item:hover {
  background-color: #6c7586;
}

.sidebar-item-active {
  height: 3rem;
  background-color: #6c7586;
}

.mb-0 {
  margin-bottom: 0;
}

.ml-0 {
  margin-left: 0;
}

.pl-0 {
  padding-right: 0;
}

body,
p,
h2,
h3,
h4,
h5,
input {
  font-family: "Muli", sans-serif;
  letter-spacing: 0.05em;
  color: #767676;
}

/*div {*/
/*    transition-timing-function: ease;*/
/*    transition: all 0.5s;*/
/*}*/

body,
html,
#root {
  height: 100%;
}

button:active {
  color: #fff;
  background-color: #3c4b64;
}

a:hover {
  color: black;
  text-decoration: none;
}

button:focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(205, 222, 255, 0) !important;
}

input:focus {
  outline: 0;
  border-color: #3c4b64 !important;
  box-shadow: 0 0 0 0 rgba(205, 222, 255, 0) !important;
}

.dropdown-item:active {
  color: #fff;
  background-color: #3c4b64;
}

h1 {
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 35px;
  letter-spacing: 0.04em;
  color: #000000;
  margin-bottom: 2px;
}

h3 {
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 14px;
  color: #5a5a5a;
}

ul {
  list-style-type: none;
}

.text {
  font-style: normal;
  font-weight: lighter;
  color: #5a5a5a;
}

.bold {
  font-style: normal;
  font-weight: bold;
  color: #5a5a5a;
}

th {
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 14px;
  color: #5a5a5a;
}

td {
  padding-top: 10px;
}

p {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  color: #5a5a5a;
  margin-bottom: 0;
}

.select__single-value {
  color: #5a5a5a !important;
}

.form-control {
  color: #5a5a5a;
  border-color: #cbcbcb;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
}

.primary-button {
  background: #3c4b64;
  border-radius: 4px;
  color: white;
  border: 1px solid #3c4b64;
  padding: 0.5rem 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  text-align: center;
}

.outline-button {
  background: white;
  border-radius: 4px;
  border: 1px solid rgba(60, 75, 100, 0.77);
  color: #3c4b64;
  padding: 0.5rem 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
}

.menu-button {
  background-color: transparent;
  border: none;
  color: #3c4b64;
  margin-left: -12px !important;
}

.menu-button:active {
  background-color: transparent;
}

@media only screen and (min-width: 800px) {
  .menu-button {}
}

.outline-p {
  background: white;
  border-radius: 4px;
  border: 0.5px solid rgba(60, 75, 100, 0.3);
  color: #3c4b64;
  padding: 0.7rem 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  height: 36px;
  line-height: 15px;
  text-align: left;
}

.custom-file-inputz::-webkit-file-upload-button {
  visibility: hidden;
}

.custom-file-inputz {
  margin-top: 4px;
  width: 80px;
  opacity: 100%;
}

.custom-file-inputz::before {
  content: "File";
  display: inline-block;
  background: white;
  border-radius: 4px;
  border: 1px solid rgba(60, 75, 100, 0.77);
  color: #3c4b64;
  padding: 0.5rem 1rem;
  font-style: normal;
  font-weight: normal;
  width: 80px;
  margin-top: auto;
  margin-bottom: auto;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  opacity: 100%;
}

.custom-file-inputz:hover::before {
  border-color: black;
}

.custom-file-inputz:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

.scrollable {
  overflow-y: scroll;
}

.icon-button {
  background-color: transparent;
  border: none;
  color: #3c4b64;
}

.flat-button {
  background-color: transparent;
  border: none;
  color: #3c4b64;
}

.actions-column {
  width: 80px;
}

.main-container {
  margin: 0 3rem;
  margin-bottom: 2rem;
  padding: 30px;

  background: #ffffff;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.25);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  min-width: 0;
}

.log-panel {
  border: 0.5px solid #5a5a5a;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 1.5rem 1.5rem;
  max-height: 200px;
  overflow-y: scroll;
}

.log-panel-unlimited {
  border: 0.5px solid #5a5a5a;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 1.5rem 1.5rem;
}

.underline-input {
  width: 100%;
  border: 0;
  border-bottom: 0.5px solid #5a5a5a;
  padding: 0.2rem;
}

.title-value-widget {
  height: 100%;
}

.dropdown-toggle::after {
  display: inline-block;
  vertical-align: 0.1em;
  margin-left: 0.6em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.form-control {
  min-height: 38px;
}

.error-message {
  color: #c60000;
  text-transform: capitalize;
  font-size: 18px;
  font-family: Amiri, serif;
}

.configuration-editor {
  width: 400px;
  box-shadow: 0 4px 40px rgba(0, 0, 0, 0.25);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.dashboard-chart {
  margin-left: 0px;
}

.recharts-default-tooltip {
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  /*width: 10rem;*/
  padding: 0.5rem 1rem !important;
  background-color: #ffffffbf !important;
  backdrop-filter: blur(1px);
}

.recharts-tooltip-item {
  font-style: normal;
  font-weight: normal;
  font-size: 45px;
  line-height: 15px;
  color: #5a5a5a !important;
  margin-bottom: 0.5rem;
  margin-top: 2rem;
  margin-right: 2rem;
  text-align: end;
  width: 100%;
  font-family: Amiri, serif;
}

.recharts-tooltip-label {
  font-style: normal;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 11px;
  line-height: 15px;
  color: #5a5a5a !important;
  margin-bottom: 0;
  margin-top: 0.5rem !important;
}

.recharts-tooltip-item-name {
  display: none;
}

.recharts-tooltip-item-separator {
  display: none;
}

.circle-picker {
  padding: 5px;
}

.react-confirm-alert-overlay {
  z-index: 1000;
}

.react-confirm-alert {
  overflow-x: auto;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-preview {
  background-color: #3c4b64;
  height: 193px;
  max-width: 700px;
  border-radius: 6px;
  object-fit: contain;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
}

.product-logo {
  background-color: #3c4b64;
  height: 120px;
  max-width: 100%;
  object-fit: contain;
}

.scroll-x {
  overflow-x: auto !important;
  max-width: 100%;
}

.progress {
  height: 6px;
  border-radius: 6px;
}

.progress-bar {
  background-color: #4a5369;
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.form-control:disabled {
  background-color: #ccc;
}

.table-row {
  user-select: none;
}