.dashboard-editor {
    position: absolute;
    bottom: 44px;
    left: 0;
    border: 0.5px solid #5A5A5A;
    box-sizing: border-box;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    background-color: #ffffffbf;
    backdrop-filter: blur(2px);
}

.dashboard-editor-item {
    background: transparent;
    border: none;
    text-transform: capitalize;
    color: #3C4B64;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    text-align: left;
    transition: background-color 1s ease;
}

.dashboard-editor-item:hover{
    background-color: rgba(255, 255, 255, 0.8);
}

.dashboard-editor-title {
    margin-left: 1rem;
    margin-top: 0.35rem;
}

.dashboard-editor-icon {
    margin-right: 0.5rem;
    margin-top: -7px;
}

.dashboard-editable-row {
    position: absolute;
    width: 100%;
    margin-left: auto;
    padding-top: 0.2rem;
    z-index: 10;
    padding-right: 0.8rem;
}

.dashboard-value {
    font-family: Amiri, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 62px;
    line-height: 54px;
    color: #FFFFFF;
    margin-bottom: 6px;
    margin-right: 3px;
}

.dashboard-widget {
    background: rgba(76, 85, 100, 0.77);
    border: 0.5px solid #5A5A5A;
    box-sizing: border-box;
    padding: 0.5rem;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
}

.dashboard-gateway-widget {
    background: #666666;
    border: 0.5px solid #5A5A5A;
    box-sizing: border-box;
    padding: 0.5rem;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
}

.dashboard-error-message {
    color: red;
    margin-top: 10px;
    margin-right: 5rem;
}