.content {
    position: relative;
    width: 320px;
    height: 240px;
}

.content > div {
    position: absolute;
    width: 320px;
    height: 50px;
    overflow: visible;
    pointer-events: auto;
    transform-origin: 50% 50% 0px;
    border-radius: 5px;
    color: white;
    line-height: 50px;
    padding-left: 16px;
    font-size: 14.5px;
    background: #4a5369;
    text-transform: uppercase;
    letter-spacing: 2px;
}
